@import "../../libs/elm-lib/src/scss_parts/colors";
@import "~bootstrap/scss/bootstrap";

$navigationWidth: 15.25rem;
$contentHorizontalPadding: 4rem;

$panelVerticalPadding: 2rem;
$panelHorizontalPadding: 2rem;

$zIndex_datePicker: 1;
$zIndex_menu: 10;
$zIndex_menuBar: 20;
$tooltip_zIndex: 30;
$zIndex_mapMarkerPopup: 40;
$zIndex_connectionError: 100;
$zIndex_fullscreen: 1000;



$almost-black: #212721;
$mid-grey: #aaaaaa;
$light-grey: #cccccc;
$light-grey: #e7e7e8;
$almost-white: #f2f2f2;

$yellow: #f3e033;

$orange: #f99e23;
$darker-orange: #f77700;

$dark-green: #3cb878;
$green: #accd4c;
$lighter-green: #c3dc7a;

$turquoise: #12ac8f;
$darker-turquoise: #00916a;

$blue: #5eb9e9;
$darker-blue: #1c7ed1;

$purple: #8560a8;

$magenta: #eb088c;
$darker-magenta: #e2005c;

// Blockchain event colors
$certificate-created: $purple !default;
$certificate-assigned: $dark-green !default;
$certificate-transferred: $yellow !default;



$grey50: #505050;


@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}






html {
  height: 100%;
}
body {
  background: $body-bg;
  color: $body-color;
  height: 100%;
}

.logo-box {
  img {
    max-height: 85px;

  }
  margin-bottom: 120px;
}

.login-button-box {
  .btn {
    padding: 20px 90px;
  }
}

.login {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.onboarding {
  .logo-box {
    margin-top: 36px;
    margin-bottom: 36px;
  }
}


.logo-box {
  img {
    max-width: 350px;
    //width: 100%;
  }
}
